import React from 'react'
import Layout from '../../components/layout/layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeThree from "../../components/layout/sidemenu/sidemenu_se_three"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import Introduction from '../../components/page-sections/introduction'
import ButtonTop from '../../components/buttons/button-top'
import ButtonNext from '../../components/buttons/button-next'

import rowImage from '../../queries/images/row-image'

import unit3Icon from '../../images/icons/unit_03_icon.svg'
import globalTemp07 from '../../images/student-pages/unit-3/07_global_temp_record.gif'
import globalTempNASA08 from '../../images/student-pages/unit-3/08_global_temp_record_british_nasa.gif'


const YourTemperatureConnections31 = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - 3.1 Your Temperature Connections" />
    <Container className="mb-5" fluid>
      <Row>
        <Col md="auto">
          <SideBarSeThree location={props.location.pathname} />
        </Col>
        <Col>
          <CCBreadcrumb
            pathname={props.location.pathname}
            title={'3.1 Your Temperature Connections'}
            replace={props.replace}
          />
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
          <Card>
            <Card.Body>

              <ContentHeader
                icon={ unit3Icon }
                iconAlt="Unit 3 icon"
                studentPageHeader="Unit 3: Carbon in the Future &amp; You"
                unitNumber="3.1"
                studentPageHeaderSub="Your Temperature Connections"
                sectionA={ <>Temperature Averages</> }
                sectionALink="unit-3/3.1-your-temp-connections/#temperature-averages"
                sectionB={ <>Taking Earth's Temperature</> }
                sectionBLink="unit-3/3.1-your-temp-connections/#taking-earths-temperature"
                sectionC={ <>Manipulating Models</> }
                sectionCLink="unit-3/3.1-your-temp-connections/#manipulating-models"
                sectionD={ <>Reflect and Connect</> }
                sectionDLink="unit-3/3.1-your-temp-connections/#reflect-and-connect"
              />

              <FocusQuestion
                focusQuestionBodyText={ <>What is the pattern for the average temperature for Earth?</> }
              />

              <Introduction
                paragraphOne={
                  <>
                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid mb-3"
                          fluid={props.data.image3.childImageSharp.fluid}
                          alt="Network stations"
                        />
                      </Col>
                    </Row>
                    <Img
                      className="smallImageRight ml-3 mb-3 img-fluid d-none d-md-block mb-2"
                      fluid={props.data.image3.childImageSharp.fluid}
                      alt="Network stations"
                    />

                    <p>
                      In Units 1 and 2 of <em>Carbon Connections</em>, you investigated carbon and climate in the past and present. You saw some ways that humans influence the carbon cycle. This is important because it relates to future climate. That is where you can be part of the solution to address climate change. But remember, it is not just you addressing this issue: It is you and thousands of students like you who will make a difference.
                    </p>
                  </>
                }

                paragraphTwo={
                  <>
                    <p>In Lesson 3.1, you will start with an activity that shows how scientists determine the Earth's temperature. Then, you will explore Earth's temperature over the past 120 years. With this global record, you can test factors that influence Earth's temperature. What you will learn in Lesson 3.1 includes:</p>
                    <ul>
                      <li>A network of climate stations gives a single average for global temperature per month.</li>
                      <li>The global temperature varies, but it also shows an overall increasing temperature.</li>
                      <li>Satellite-based temperature data confirm the ground-based temperature data.</li>
                    </ul>
                  </>
                }

                paragraphThree={
                  <>
                    <p>For Lesson 3.1, use the lesson focus question to guide what you learn about carbon and climate.</p>
                  </>
                }
              />

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="temperature-averages" className="cardHeader">
                      <h2>
                        A. <span className="headerFontBlue">Temperature Averages</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Many times in science, you will use an average, or mean, to tell you about sets of measurements. An example is a batting average in baseball. Or, you might be watching your grade point average; it tells you about the kind of grades you are getting in school. In a similar way, scientists use temperature averages as a way to study Earth's climate. Understanding these averages will help you answer the focus question for Lesson 3.1.
                      </p>

                      <p className="lead">Measuring Temperature Activity</p>
                      <p>
                        Do the Measuring Temperature activity with your class. You will model how many climate stations are used to get a single, average temperature for Earth. You can learn more about networks and averages in the Grid Averages activity at the end of this lesson.
                      </p>

                      <p className="lead"><em>Materials per Team</em></p>
                        <ul>
                          <li>Container for water (cup or beaker)</li>
                          <li>Thermometer in Celsius (&deg;C)</li>
                          <li>Paper towel</li>
                        </ul>

                      <p className="lead"><em>Process and Procedure</em></p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image4.childImageSharp.fluid}
                            alt="Temperature measurement experiment"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight img-fluid d-none d-md-block ml-3"
                        fluid={props.data.image4.childImageSharp.fluid}
                        alt="Temperature measurement experiment"
                      />

                      <p>
                        Follow your teacher's instructions to set up the activity, and then do the steps below:
                      </p>

                      <ol>
                        <li>Sketch a map of your classroom. Show the location of each water container.</li>
                        <li>When directed by your teacher, place the thermometer in the water. Measure and record the water temperature.</li>
                        <li>Share temperature data for each container with your class. Make a table of temperature data, or write the temperature on your map.</li>
                        <li>Determine the average for all the temperature data. Draw a box around this value.</li>
                        <li>The temperature of the beakers around the room may change. Listen to your teacher for whether you will do another measurement. If so, determine if the average temperature of all beakers changes too.</li>
                        <li>Consider the container you measured, or any other single container in the classroom. How well does its temperature reflect the mean temperature of all containers?</li>
                      </ol>

                      <p>
                        If you want to learn more about networks and averages, try The Grid Game
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="taking-earths-temperature" className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">The Grid Game</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p className="lead">The Grid Game</p>
                      <p>
                        Scientists use measurements from a network of climate stations to calculate a single value for Earth's temperature. Determining averages from grids shows you another way to model how scientists calculate the Earth's temperature. Rather than use thousands of climate stations, you can use an average from a grid of nine numbers.
                      </p>

                      <p>
                        When you click on each grid number, you will see a 3x3 group of numbers. Use the steps below for the grids.
                      </p>

                      <p className="lead"><em>Process and Procedure</em></p>
                      <ol>
                        <li>Calculate the average (or mean) for grid #1.</li>
                        <li>Write your answer in a table (example shown below).</li>
                        <li>Determine the averages for grids 2-5.</li>
                        <li>Do you see a trend or pattern in the averages? If so, what is it?</li>
                        <li>Calculate how the averages for grids 1-5 changed relative to the average for grid 1.</li>
                        <li>Do you see a trend for values in step 5? How does a trend compare with what you saw in step 4?</li>
                      </ol>

                      <table className="table table-bordered table-hover table-responsive-md studentTable">
                        <thead>
                          <tr className="text-center">
                            <th className="table-active" scope="col">Grid #</th>
                            <th className="table-active" scope="col">Average for Grid</th>
                            <th className="table-active" scope="col">Change from Gird #1</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Grid #1</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Grid #2</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Grid #3</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Grid #4</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Grid #5</td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="taking-earths-temperature" className="cardHeader">
                      <h2>
                        B. <span className="headerFontBlue">Taking Earth's Temperature</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image5.childImageSharp.fluid}
                            alt="Power station"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight img-fluid d-none d-md-block mb-3 ml-3"
                        fluid={props.data.image5.childImageSharp.fluid}
                        alt="Power station"
                      />

                      <p>
                        To learn about Earth's climate, scientists need to measure the Earth's temperature. But where would you measure Earth's temperature? You started thinking about this in step 6. If you measured at one place, such as the equator, then the temperature would be too hot to represent all of Earth. If you measured at the poles, the temperature would be too cold. Perhaps you could go halfway, to latitude 45°. But that would not work, because most land and continents are north of the equator. This also raises the question of whether to measure over land, ocean, or maybe both.
                      </p>

                      <p>
                        To address the issue of where to measure, scientists use measurements from many thousands of stations. These stations make a network that spans Earth. At each station, temperatures are measured whenever possible. Scientists use the daily data to calculate an average per month. Then for each month, the monthly averages for each station in the network are used for one global average. You calculated an average in a similar way in the Measuring Temperature activity.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3 imageBorder"
                            fluid={props.data.image6.childImageSharp.fluid}
                            alt="Global climate network temperature stations"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter img-fluid imageBorder d-none d-md-block mb-3"
                        fluid={props.data.image6.childImageSharp.fluid}
                        alt="Global climate network temperature stations"
                      />

                      <p>
                        In the case of these temperature data, a simple average does not work. Areas with many stations, such as the United States or Europe, would be overrepresented. This would give an offset, or <strong>bias</strong>, for areas that have many stations. Thus, scientists combine the data by regions so that the monthly mean for Earth is not biased toward areas with more stations. Because the stations cover all of Earth, the result is one value for all of Earth&mdash;the global average.
                      </p>

                      <p>
                        Watch the animation of global temperatures from 1880-2008. Notice that:
                      </p>

                      <ul>
                        <li>The "clock" in the upper-left shows the 5-year periods for the monthly averaged data.</li>
                        <li>The upper-right corner shows the temperature anomaly (Ta) for the globe. The <strong>anomaly</strong> is how much a value differs from the average in 1951-1980. For example, over the period of 1990-1994, the Ta was +0.24&deg;C warmer than the average for 1951-1980.</li>
                        <li>Colors show regional anomalies. Blues are colder than the 1951-1980 average; and yellow, oranges, and reds are above average. Gray means not enough data.</li>
                      </ul>

                      <p className="lead">Global Temperature Animation</p>
                      <div id="" className="embed-container mb-3">
                        <iframe title="ta_5yr" className="videoSetting" src="https://player.vimeo.com/video/307893211" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>

                      <p>
                        Watch the animation of global temperatures again, and then complete steps 7-9.
                      </p>

                      <ol start="7">
                        <li>Estimate the temperature anomaly for the year you were born. To do that, go to the five-year interval with the year of your birth in it (for example, for 1995, go to 1993-1997).</li>
                        <li>What regions of the globe show large anomalies at that time? Were they cold or warm?</li>
                        <li>What trend of temperature do you see over the entire animation? Does this trend match the changing values of Ta (upper-right corner)?</li>
                      </ol>

                      <p className="lead">Global Temperature Record: NASA/GISS Scientists</p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ globalTemp07 }
                            alt="Global temperature record"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter img-fluid d-none d-md-block mb-3"
                        src={ globalTemp07 }
                        alt="Global temperature record"
                      />

                      <ol start="10">
                        <li>
                          NASA scientists have collected temperatures from the global network. They have average data for temperatures from 1890-2010.
                          <ol type="a">
                            <li>What trend of temperature do you see in their data?</li>
                            <li>How does this trend compare with the trend that you described in step 9 for the animation?</li>
                          </ol>
                        </li>
                      </ol>

                      <p className="lead">Global Temperature Record: NASA/GISS Scientists &amp; British Climate Scientists</p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ globalTempNASA08 }
                            alt="Global temperature record"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter img-fluid d-none d-md-block mb-3"
                        src={ globalTempNASA08 }
                        alt="Global temperature record"
                      />

                      <ol start="11">
                        <li>
                          Climate scientists from other countries have also analyzed temperature data from the global network of climate stations. Temperature results for British climate scientists are in the graph. Compare their temperature results with the NASA temperatures by completing the following sentences. <br />
                          <em>A similarity in the two data sets is &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;.</em> <br />
                          <em>A difference in the two data sets is &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;.</em>
                        </li>
                      </ol>

                      <a
                        href="http://media.bscs.org/carbonconnections/images/student_edition/02_curriculum/unit3/documents/ta_ground_and_satellite.xls"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button className="mb-3">
                          Download Ta Data&nbsp;&nbsp;<i className="fas fa-cloud-download-alt"></i>
                        </Button>
                      </a>

                      <p>
                        The temperature data for Earth improved in the late 1970s for several reasons. First, the number of climate stations increased in the 1960s and 1970s. Second, a new generation of NASA satellites was launched in late 1978. One satellite looked at changes in radiation from the Sun. Another satellite was used to determine temperature of the lower atmosphere. These temperatures from satellites were an excellent way to test if the ground-based stations were accurate. Thus, data from the past 30 years (1979-2010) is the highest-quality data on Earth's climate.
                      </p>

                      <p className="lead">Ta Explorer</p>
                      <p>
                        Launch the Ta Explorer. Remember, the Ta stands for "temperature anomaly." You can use Ta Explorer to compare temperatures from different climate teams from 1979 through 2010. The data includes the ground-based stations, as well as satellite-based data. Be sure to "grab" the temperature records, and slide them up and down. This lets you compare and contrast the temperature from different research teams. You can also compare the ground-based data with the satellite-based data. This helps you see overall patterns of change in Earth's temperature.
                      </p>

                      <a
                        href="http://media.bscs.org/carbonconnections/images/student_edition/02_curriculum/unit3/documents/ta_ground_and_satellite.xls"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button className="mb-3">
                          Download Ta Data&nbsp;&nbsp;<i className="fas fa-cloud-download-alt"></i>
                        </Button>
                      </a>
                      
                      <ol start="12">
                        <li>
                          The National Oceanic and Atmospheric Administration (NOAA) also has scientists who closely monitor Earth's temperature from the climate stations. How does the NOAA temperature record compare with the NASA and British records? Use the Ta Explorer to overlay and compare these three records. <br />
                          <em>Click to select a record, then use mouse to drag the curve up or down.</em>
                        </li>
                        <li>Change scales by clicking on the "1979-2010" button. How does a ground-based record compare with a satellite-based record? For example, how do the NOAA and RSS data compare?</li>
                        <li>Using any record, look at the change from 1991 to 1992. Is the change a cooling or warming? You will study this change more in Lesson 3.2.</li>
                        <li>
                          Watch the four-minute video by NASA, "Taking Earth's Temperature." As you watch, record answers to these questions:

                          <div id="" className="embed-container mb-3 mt-3">
                            <iframe title="Taking_Earths_Temperature_580X420" className="videoSetting" src="https://player.vimeo.com/video/307893776" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                          </div>

                          <ol type="a">
                            <li>How do scientists get the most-reliable measurement of Earth's overall temperature?</li>
                            <li>What does the GLORY mission measure and monitor?</li>
                            <li>What do you think is a climate forcing? Give an example from the video to show your thinking.</li>
                          </ol>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="manipulating-models" className="cardHeader">
                      <h2>
                        C. <span className="headerFontBlue">Manipulating Models</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Investigating Earth's temperature record raises an important question: What factors affect Earth's climate? In Lesson 3.2, you will get to test several factors. You will be like a scientist as you explore this question with the <em>Carbon Connections</em> Climate Model. By adjusting the factors in the climate model, you will get to test which factors can cause Earth's temperature to change.
                      </p>

                      <div className="col-12">
                        <iframe
                          className="embed-container w-100"
                          src="https://ecm.coloradocollege.edu/interactive"
                          title="Climate model interactive"
                        />
                      </div>
                      <p>If you are unable to see the interactive, click <a href="https://ecm.coloradocollege.edu/interactive" target="_blank" rel="noopener noreferrer">here&nbsp;<i className="fas fa-external-link-alt"></i></a> to open it in a new tab.</p>

                      <p className="lead">Climate Model</p>
                      <p>
                        To think about factors that affect Earth's temperature, do the steps below:
                      </p>

                      <ol start="16">
                        <li>
                          Think of one or two factors that you think could affect the global temperature.
                          <ol type="a">
                            <li>Write these on the left side or your notebook.</li>
                            <li>On the right side, write whether you think that they might lead to warming or cooling.</li>
                            <li>Discuss your ideas with a classmate near you. Did your classmate have an idea that was different from yours? Revise your list to include any new factors you learned from your classmate.</li>
                          </ol>
                        </li>
                        <li>
                          Now open the <em>Carbon Connections</em> Climate Model. The four slide bars are four key forcings on the global temperature. You can change these forcings:
                          <ul>
                            <li><strong>El Ni&ntilde;o/La Ni&ntilde;a Cycles.</strong> These cycles occur in the Pacific Ocean at the equator.</li>
                            <li><strong>Volcanic Eruptions.</strong> These eruptions send ash and gas into the atmosphere.</li>
                            <li><strong>Solar Cycles.</strong> These are changes in solar radiation from the Sun.</li>
                            <li><strong>Anthropogenic.</strong> These are human-related influences on Earth's climate.</li>
                          </ul>
                        </li>
                        <li className="list-unstyled">
                          As you change the forcings, notice the three lines on the graph. They span more than 30 years, from 1979-2011.
                          <ul>
                            <li><strong>Black.</strong> This is the measured temperature data (Ta).</li>
                            <li><strong>Green.</strong> This model is the best match to the temperature data (that is, the "best-fit" model).</li>
                            <li><strong>Red.</strong> This shows the model results as you adjust the climate forcings.</li>
                          </ul>
                        </li>
                      </ol>

                      <p>
                        As you test the model, also notice the moving bar to the right of the graph. Before you start Lesson 3.2, see if you can determine what the red bar tells you.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        D. <span className="headerFontBlue">Reflect and Connect</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <ol start="17">
                        <li>Summarize what you have learned in Lesson 3.1 by answering the focus question: "What is the pattern of Earth's average temperature?" Use the Ta Explorer from the periods 1880-2011 and 1979-2011 for examples in your answer.</li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <ButtonNext
                ButtonText="3.2 Testing Forcings"
                NextLink="/unit-3/3.2-testing-forcings"
              />

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default YourTemperatureConnections31

export const query = graphql`
  query {
    image3: file(relativePath: { eq: "student-pages/unit-3/03_network_climate_stations.jpg" }) {
      ...rowImage
    }
    image4: file(relativePath: { eq: "student-pages/unit-3/04_temp_experiment.jpg" }) {
      ...rowImage
    }
    image5: file(relativePath: { eq: "student-pages/unit-3/05_station.jpg" }) {
      ...rowImage
    }
    image6: file(relativePath: { eq: "student-pages/unit-3/06_global_climate_network.jpg" }) {
      ...rowImage
    }
  }
`
